import { ConnectCoreActions, ConnectCoreState } from '../hooks';
import { CallFlowStep } from './CallFlowSteps';
import { CallSteps } from './CallSteps';
import { OutgoingSignal } from './Signals';

export interface FlowProps extends ConnectCoreState {
  actions: ConnectCoreFlowActions;
  outgoingSignal: OutgoingSignal;
}
export interface ConnectCoreFlowActions extends ConnectCoreActions {
  queueMmsCallFlowStep?: (callFlowStep: CallFlowStep, nextCallStep: CallSteps) => Promise<void>;
}
export type Flow = (flowProps: FlowProps) => CallSteps;

export enum Flows {
  GUEST_FLOW = 'GUEST_FLOW',
  MEMBER_FLOW = 'MEMBER_FLOW',
  CLINICIAN_ACCESS_BUBBLE_FLOW = 'CLINICIAN_ACCESS_BUBBLE_FLOW',
}
