export interface AsyncQueueProps {
  getter: () => Promise<any[]>;
  setter: (newQueue: any[]) => Promise<void>;
}
export default class AsyncQueue {
  getter: () => Promise<any[]>;
  setter: (newQueue: any[]) => Promise<void>;

  constructor({ getter, setter }: AsyncQueueProps) {
    this.setter = setter;
    this.getter = getter;
  }

  public async peek() {
    const queueArray = await this.getter();
    return queueArray[0];
  }

  public async push(item: any): Promise<void> {
    const currentQueueArray = await this.getter();
    await this.setter([...currentQueueArray, item]);
  }

  public async pop() {
    const firstItem = this.peek();
    const currentQueueArray = await this.getter();
    const newQueueArray = currentQueueArray.slice(1);
    await this.setter(newQueueArray);
    return firstItem;
  }

  public async getQueue(): Promise<any[]> {
    return this.getter();
  }
}
