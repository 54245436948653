import { AccessCallSignalEvents, CommonSignalEvents } from '../../types';
import { CallObserverEvents } from './CallObserver';

export enum CallObserverSignalAccessCallEvents {
  clientGranted = 'signal:consumedByClient:accessCallGranted',
  clientDeclined = 'signal:consumedByClient:accessCallDeclined',
  clientRequestAcknowledged = 'signal:consumedByClient:accessCallRequestAcknowledged',
  providerRequest = 'signal:consumedByProvider:accessCallRequest',
}

export const callObserverSignalAccessCallEventsToSignalEvent = (
  event: CallObserverEvents | null
) => {
  switch (event) {
    case CallObserverSignalAccessCallEvents.clientGranted:
      return AccessCallSignalEvents.GRANTED;
    case CallObserverSignalAccessCallEvents.clientDeclined:
      return AccessCallSignalEvents.DENIED;
    case CallObserverSignalAccessCallEvents.clientRequestAcknowledged:
      return CommonSignalEvents.RECEIVED;
    case CallObserverSignalAccessCallEvents.providerRequest:
      return AccessCallSignalEvents.REQUEST;
    default:
      return null;
  }
};
