import { OutgoingSignal } from '../../types';
import { EventListeners } from '../EventListeners';
import Queue from '../Queue/Queue';

export enum SignalQueueEvents {
  signalAddedToQueue = 'signalAddedToQueue',
}

export default class SignalQueue extends EventListeners {
  constructor() {
    super();
  }

  private queue: Queue = new Queue();

  public emit(event: SignalQueueEvents, data?: any): void {
    super.emit(event, data);
  }

  public on(event: SignalQueueEvents, callback: (data?: any) => void): () => void {
    return super.on(event, callback);
  }

  public isEmpty(): boolean {
    return !this.queue.getQueue().length;
  }

  public pop(): OutgoingSignal | undefined {
    return this.queue.pop();
  }

  public peek(): OutgoingSignal | undefined {
    return this.queue.peek();
  }

  public push(signal: OutgoingSignal): void {
    this.queue.push(signal);
    this.emit(SignalQueueEvents.signalAddedToQueue);
  }

  public matchesPreviousSignalInHistory(signal: OutgoingSignal): boolean {
    const previousSignal = this.queue.getHistory()[this.queue.getHistory().length - 1];
    if (!previousSignal) return false;
    if (previousSignal.data !== signal.data) return false;
    if (previousSignal.type !== signal.type) return false;
    if (previousSignal.to !== signal.to) return false;
    return true;
  }

  public reset(): void {
    this.queue.reset();
  }
}
