import { CallObserverEvents } from './CallObserver';

export const latestEvents = (events: CallObserverEvents[]) => {
  return (...comparands: CallObserverEvents[]) => getLatestEvent(events, ...comparands);
};

export const getLatestEvent = (
  events: CallObserverEvents[],
  ...comparands: CallObserverEvents[]
): CallObserverEvents | null => {
  for (let i = events.length - 1; i >= 0; i--) {
    for (let j = 0; j < comparands.length; j++) {
      if (events[i] === comparands[j]) return comparands[j];
    }
  }
  // if neither are found
  return null;
};

// specific to CallObserver
export const canEmitEvent = (
  latestEvent: CallObserverEvents | null,
  comparand: CallObserverEvents
) => {
  return latestEvent === null || latestEvent === comparand;
};
