import React from 'react';
import cn from 'classnames';
import styles from './AccessRequestBubble.module.css';

export interface AccessRequestBubbleMessages {
  name: string;
  body: string;
  accept: string;
  decline: string;
}

export interface AccessRequestBubbleProps {
  messages: AccessRequestBubbleMessages;
  className?: string;
  onAccept: () => void;
  onDecline: () => void;
  style?: React.StyleHTMLAttributes<HTMLDivElement>;
}

export default function AccessRequestBubble({
  messages,
  onAccept,
  onDecline,
  className = '',
  style,
}: AccessRequestBubbleProps) {
  return (
    <div
      className={cn(styles.container, className)}
      data-testid="access-request-bubble"
      style={style}
    >
      <p className={styles.copy}>
        <strong>{messages.name}</strong> {messages.body}
      </p>
      <div>
        <button onClick={onAccept} className={styles.accept}>
          {messages.accept}
        </button>
        <button onClick={onDecline} className={styles.decline}>
          {messages.decline}
        </button>
      </div>
    </div>
  );
}
