import { addDays, isAfter } from 'date-fns';
import { asyncDelay } from '../../helpers/general';
import AsyncQueue from '../AsyncQueue';
import { AsyncQueueProps } from '../AsyncQueue/AsyncQueue';

const EXPIRE_AFTER_DAYS = 30;
const SUCCESS_DELAY = 100;
const FAILURE_DELAY = 2000;

export interface RetryQueueProps extends AsyncQueueProps {
  processItem?: (item: any) => Promise<void>;
}
export default class RetryQueue extends AsyncQueue {
  public isProcessing = false;
  public processItem;
  constructor({ processItem, ...rest }: RetryQueueProps) {
    super(rest);
    this.processItem = processItem;
  }

  public async push(item: any): Promise<void> {
    const dateTimeCreated = new Date().toISOString();
    const itemToQueue = {
      dateTimeCreated,
      item,
    };
    const currentQueue = await this.getQueue();
    if (currentQueue.includes(item)) {
      return;
    }
    await super.push(itemToQueue);
  }

  public async pop() {
    const item = await super.pop();
    return item?.item;
  }

  public async peek() {
    const item = await super.peek();
    return item?.item;
  }

  public async getQueue() {
    const localQueue = await super.getQueue();
    return localQueue.map((item) => item.item);
  }

  public async process() {
    if (this.isProcessing) return;
    this.isProcessing = true;

    let failedAttempts = 0;
    while ((await this.peek()) !== undefined) {
      if (failedAttempts >= 20) {
        break;
      }
      const item = await this.peek();
      try {
        await this.processItem(item);
        await this.pop();
      } catch (e) {
        failedAttempts += 1;

        const fullItem = await super.peek();
        const itemCreatedAt = new Date(fullItem.dateTimeCreated);
        const expiresAfter = addDays(itemCreatedAt, EXPIRE_AFTER_DAYS);
        if (isAfter(new Date(), expiresAfter)) {
          await this.pop();
        }

        await asyncDelay(failedAttempts * FAILURE_DELAY);
        continue;
      }
      await asyncDelay(SUCCESS_DELAY);
    }

    this.isProcessing = false;
  }

  public async pushAndProcess(item: any): Promise<void> {
    await this.push(item);
    await this.process();
  }
}
