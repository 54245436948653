import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { guestFlow } from '../../flows';
import { clinicianRequestBubbleFlow } from '../../flows/clinician-request-bubble.flow';
import { delay } from '../../helpers/general';
import { getCallFlowQueue, setCallFlowQueue } from '../../lib/CallFlowSteps/helpers';
import CallObserver, {
  CallObserverEvents,
  CallObserverSessionEvents,
} from '../../lib/CallObserver/CallObserver';
import {
  CallObserverSignalAccessCallEvents,
  callObserverSignalAccessCallEventsToSignalEvent,
} from '../../lib/CallObserver/callObserverAccessCallHelpers';
import { getLatestEvent } from '../../lib/CallObserver/helpers';
import RetryQueue from '../../lib/RetryQueue/RetryQueue';
import SignalQueue, { SignalQueueEvents } from '../../lib/SignalQueue/SignalQueue';
import {
  Flows,
  SignalEvents,
  UserType,
  CallSteps,
  Connection,
  Stream,
  OutgoingSignal,
} from '../../types';
import { CallFlowStep, ExternalCallFlowStepPartial } from '../../types/CallFlowSteps';

const callObserver = new CallObserver();
const signalQueue = new SignalQueue();

const OTSessionEventHandlers = {
  sessionConnected: () => callObserver.onSessionConnected(),
  connectionCreated: (e) => callObserver.onConnectionCreated(e),
  streamCreated: (e) => callObserver.onStreamCreated(e),
  streamDestroyed: (e) => callObserver.onStreamDestroyed(e),
  connectionDestroyed: (e) => callObserver.onConnectionDestroyed(e),
  signal: (e) => callObserver.onSignal(e),
};

export interface ConnectCoreState {
  activeParticipants: UserType[];
  streams: Stream[];
  connections: Connection[];
  events: CallObserverEvents[];
  metadata: { [key: string]: string };
  signals: { accessCall: SignalEvents | null };
}

export interface ConnectCoreReturnData extends ConnectCoreState {
  currentCallStep: CallSteps;
  callObserver: CallObserver;
  OTSessionEventHandlers: typeof OTSessionEventHandlers;
  actions: ConnectCoreActions;
  outgoingSignal?: OutgoingSignal;
}

export const initialState: ConnectCoreState = {
  activeParticipants: [],
  streams: [],
  connections: [],
  events: [],
  metadata: {},
  signals: { accessCall: null },
};

export interface ConnectCoreActions {
  forceEndCallStep: (forceEndCallReason?: { reason: string }) => void;
  resetState: () => void;
  queueSignal: (signal: OutgoingSignal) => void;
  accessGranted: () => void;
  accessDeclined: () => void;
}

export interface UseConnectCoreProps {
  flow?: Flows;
  mmsCallFlowConfig?: {
    emit: (callFlowStep: CallFlowStep) => Promise<void>;
    setupData: ExternalCallFlowStepPartial;
    storeSetter: (key: string, data: any) => Promise<void>;
    storeGetter: (key: string) => Promise<any>;
  };
  resetConfig?: {
    beforeReset?: (callStep: CallSteps) => void;
    resetDelay?: number;
  };
  loggingEnabled?: boolean;
}
export const useConnectCore = ({
  flow,
  mmsCallFlowConfig,
  resetConfig,
  loggingEnabled = false,
}: UseConnectCoreProps): ConnectCoreReturnData => {
  const [state, setState] = useState<ConnectCoreState>(initialState);
  const [outgoingSignal, setOutgoingSignal] = useState<OutgoingSignal>();
  const [freshInstance, setFreshInstance] = useState(true);
  const [currentCallStep, setCallStepState] = useState<CallSteps>(CallSteps.NOT_STARTED);
  const memoizedCallObserver = useMemo(() => callObserver, []);
  const retryQueueRef = useRef<RetryQueue>();

  useEffect(() => {
    if (loggingEnabled) {
      callObserver.enableLogging();
      console.log('[CCC] Logging Enabled');
    }
  }, [loggingEnabled]);

  useEffect(() => {
    if (!mmsCallFlowConfig) return;
    if (!retryQueueRef.current) {
      retryQueueRef.current = new RetryQueue({
        setter: (data: CallFlowStep[]) =>
          setCallFlowQueue({ data, setter: mmsCallFlowConfig.storeSetter }),
        getter: () => getCallFlowQueue({ getter: mmsCallFlowConfig.storeGetter }),
        processItem: mmsCallFlowConfig.emit,
      });
    }
  }, [mmsCallFlowConfig, retryQueueRef]);

  const updateState = useCallback(() => {
    const newState = {
      activeParticipants: callObserver.getActiveParticipants(),
      streams: callObserver.getStreams(),
      connections: callObserver.getConnections(),
      events: callObserver.getEvents(),
      metadata: callObserver.getMetadata(),
      freshInstance: false,
      signals: {
        accessCall: callObserverSignalAccessCallEventsToSignalEvent(
          getLatestEvent(
            callObserver.getEvents(),
            CallObserverSignalAccessCallEvents.clientDeclined,
            CallObserverSignalAccessCallEvents.clientGranted,
            CallObserverSignalAccessCallEvents.clientRequestAcknowledged,
            CallObserverSignalAccessCallEvents.providerRequest
          )
        ),
      },
    };
    setState(newState);
  }, []);

  useEffect(() => {
    // if signalQueue.queue is mutated before, during or after a setState call,
    // then the hook doenst rerender, the useEffect ensures we only pop after
    // the rerender, not blocking it.
    if (signalQueue.peek() === outgoingSignal) signalQueue.pop();
  }, [outgoingSignal]);

  const updateOutgoingSignal = useCallback(() => {
    if (signalQueue.peek() && signalQueue.peek() !== outgoingSignal) {
      setOutgoingSignal(signalQueue.peek());
    }
  }, [outgoingSignal]);

  const queueSignal = useCallback((signal: OutgoingSignal) => {
    if (!signalQueue.matchesPreviousSignalInHistory(signal)) {
      signalQueue.push(signal);
    }
  }, []);

  // events to state
  useEffect(() => {
    if (freshInstance) {
      setFreshInstance(false);
    }

    const removeSessionConnected = callObserver.on(
      CallObserverSessionEvents.sessionConnected,
      updateState
    );
    const removeConsultantJoined = callObserver.on(
      CallObserverSessionEvents.consultantJoined,
      updateState
    );
    const removePatientJoined = callObserver.on(
      CallObserverSessionEvents.patientJoined,
      updateState
    );
    const removeGuestJoined = callObserver.on(CallObserverSessionEvents.guestJoined, updateState);
    const removeConnectionCreated = callObserver.on(
      CallObserverSessionEvents.connectionCreated,
      updateState
    );
    const removeConnectionDestroyed = callObserver.on(
      CallObserverSessionEvents.connectionDestroyed,
      updateState
    );
    const removeStreamCreated = callObserver.on(
      CallObserverSessionEvents.streamCreated,
      updateState
    );

    const removeStreamDestroyed = callObserver.on(
      CallObserverSessionEvents.streamDestroyed,
      updateState
    );
    const removeConsultantLeft = callObserver.on(
      CallObserverSessionEvents.consultantLeft,
      updateState
    );
    const removePatientLeft = callObserver.on(CallObserverSessionEvents.patientLeft, updateState);
    const removeGuestLeft = callObserver.on(CallObserverSessionEvents.guestLeft, updateState);
    const removeAccessRequest = callObserver.on(
      CallObserverSignalAccessCallEvents.providerRequest,
      updateState
    );
    const removeAccessReceived = callObserver.on(
      CallObserverSignalAccessCallEvents.clientRequestAcknowledged,
      updateState
    );
    const removeAccessGranted = callObserver.on(
      CallObserverSignalAccessCallEvents.clientGranted,
      updateState
    );
    const removeAccessDenied = callObserver.on(
      CallObserverSignalAccessCallEvents.clientDeclined,
      updateState
    );
    const removeForcedEndCall = callObserver.on(
      CallObserverSessionEvents.forcedEndCall,
      updateState
    );
    const removeSignalAddedToQueue = signalQueue.on(
      SignalQueueEvents.signalAddedToQueue,
      updateOutgoingSignal
    );

    return () => {
      removeSessionConnected();
      removeConsultantJoined();
      removePatientJoined();
      removeGuestJoined();
      removeConsultantLeft();
      removePatientLeft();
      removeGuestLeft();
      removeAccessGranted();
      removeAccessDenied();
      removeAccessReceived();
      removeAccessRequest();
      removeForcedEndCall();
      removeSignalAddedToQueue();
      removeConnectionCreated();
      removeConnectionDestroyed();
      removeStreamCreated();
      removeStreamDestroyed();
    };
  }, [updateState, updateOutgoingSignal, currentCallStep, freshInstance]);

  const resetInstances = useCallback(() => {
    callObserver.resetInstance();
    signalQueue.reset();
  }, []);

  const resetState = useCallback(() => {
    resetInstances();
    setOutgoingSignal(undefined);
    setState(initialState);
    setCallStepState(CallSteps.NOT_STARTED);
    if (loggingEnabled) {
      callObserver.enableLogging();
    }
    setFreshInstance(true);
  }, [resetInstances, loggingEnabled]);

  const actions = useMemo(
    () => ({
      accessGranted: () => callObserver.emit(CallObserverSignalAccessCallEvents.clientGranted),
      accessDeclined: () => callObserver.emit(CallObserverSignalAccessCallEvents.clientDeclined),
      forceEndCallStep: (forceEndCallReason?: { reason: string }) => {
        if (forceEndCallReason) {
          callObserver.addMetadata({ forceEndCallReason: forceEndCallReason.reason });
        }
        callObserver.emitForceEndCallEvent();
      },
      resetState,
      queueSignal,
    }),
    [resetState, queueSignal]
  );

  // flows
  useEffect(() => {
    let newCurrentStep = CallSteps.NOT_STARTED;
    const flowProps = {
      ...state,
      outgoingSignal,
      actions: {
        ...actions,
        queueMmsCallFlowStep: async (callFlowStep: CallFlowStep, nextCallStep: CallSteps) => {
          if (retryQueueRef.current && nextCallStep !== currentCallStep) {
            retryQueueRef.current.pushAndProcess({
              ...callFlowStep,
              ...mmsCallFlowConfig.setupData,
            });
          }
        },
      },
    };

    switch (flow) {
      case Flows.GUEST_FLOW:
        newCurrentStep = guestFlow.flow(flowProps);
        break;
      case Flows.CLINICIAN_ACCESS_BUBBLE_FLOW:
        newCurrentStep = clinicianRequestBubbleFlow(flowProps);
        break;
    }
    if (state.events.includes(CallObserverSessionEvents.forcedEndCall)) {
      newCurrentStep = CallSteps.END_CALL;
    }

    if (currentCallStep !== newCurrentStep) {
      setCallStepState(newCurrentStep);
    }
  }, [state, flow, currentCallStep, actions, retryQueueRef, outgoingSignal]);

  useEffect(() => {
    let resetOnSteps = [];
    switch (flow) {
      case Flows.GUEST_FLOW:
        resetOnSteps = guestFlow.resetOnSteps;
        break;
      case Flows.CLINICIAN_ACCESS_BUBBLE_FLOW:
        break;
    }

    if (!resetOnSteps.includes(currentCallStep)) return;
    resetInstances();
    delay(() => {
      if (resetConfig?.beforeReset) {
        resetConfig.beforeReset(currentCallStep);
      }
      resetState();
    }, resetConfig?.resetDelay || 1000);
  }, [
    currentCallStep,
    resetConfig?.beforeReset,
    resetConfig?.resetDelay,
    resetState,
    flow,
    resetInstances,
  ]);

  return {
    ...state,
    currentCallStep,
    callObserver: memoizedCallObserver,
    OTSessionEventHandlers,
    outgoingSignal,
    actions,
  };
};
