import { format } from 'date-fns';

// helpers

export const convertDateTimeStringToDate = (dateTimeString?: string): Date | undefined => {
  if (!dateTimeString) {
    return undefined;
  }
  return new Date(dateTimeString);
};

export const formatConsultationTime = (dateTime?: Date): string | undefined => {
  if (!dateTime) {
    return undefined;
  }
  return format(dateTime, 'kk:mm');
};

export const convertDateTimeToConsultationTime = (dateTimeString?: string): string | undefined => {
  const dt = convertDateTimeStringToDate(dateTimeString);
  return formatConsultationTime(dt);
};
