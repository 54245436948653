import React, { useEffect, useRef } from 'react';
import {
  SignalTypes,
  useConnectCore,
  Flows,
  CallSteps,
  ExternalCallFlowStepPartial,
  UseConnectCoreProps,
  CallFlowStep,
} from '@babylon/connect-client-core';
import { Session } from '@opentok/client';
import AccessRequestBubble from '../AccessRequestBubble';
import {
  AccessRequestBubbleMessages,
  AccessRequestBubbleProps,
} from '../AccessRequestBubble/AccessRequestBubble';

export interface AccessRequestProps
  extends Omit<AccessRequestBubbleProps, 'messages' | 'onAccept' | 'onDecline'> {
  messages: Omit<AccessRequestBubbleMessages, 'name'>;
  session?: Session;
  sessionActive: boolean;
  mmsCfsConfig?: {
    emit: (callFlowStep: CallFlowStep) => Promise<void>;
    setupData: ExternalCallFlowStepPartial;
    storeSetter: (key: string, data: any) => Promise<void>;
    storeGetter: (key: string) => Promise<any>;
  };
  mmsCfsEnabled?: boolean;
}

export default function AccessRequest({
  messages,
  session,
  sessionActive,
  mmsCfsConfig,
  mmsCfsEnabled = false,
  ...props
}: AccessRequestProps) {
  const uccConfig: UseConnectCoreProps = {
    flow: Flows.CLINICIAN_ACCESS_BUBBLE_FLOW,
  };

  if (mmsCfsConfig && mmsCfsEnabled) {
    uccConfig.mmsCallFlowConfig = mmsCfsConfig;
  }

  const { OTSessionEventHandlers, metadata, currentCallStep, outgoingSignal, actions } =
    useConnectCore(uccConfig);

  useEffect(() => {
    if (outgoingSignal) {
      session?.signal(outgoingSignal as any, () => {
        /* todo */
      });
    }
  }, [outgoingSignal]);

  useEffect(() => {
    if (!sessionActive || !session) {
      return;
    }
    session.on(OTSessionEventHandlers);
    session.on(`signal:${SignalTypes.access_call}` as 'signal', OTSessionEventHandlers.signal);
  }, [sessionActive]);

  if (!metadata?.fullName) return null;
  if (currentCallStep !== CallSteps.REQUESTING_ACCESS) return null;

  // TODO : restrict connectionId to signal sender
  // TODO: create flow for access request on CP

  return (
    <AccessRequestBubble
      messages={{
        ...messages,
        name: metadata.fullName,
      }}
      onAccept={actions.accessGranted}
      onDecline={actions.accessDeclined}
      {...props}
    />
  );
}
