import React from 'react';
import { CallSteps } from '../../types/CallSteps';

interface CallFlowProps {
  WaitingRoom: React.FC;
  ConnectingRoom: React.FC;
  AccessWaitingRoom?: React.FC;
  AccessDeniedRoom?: React.FC;
  AccessGrantedRoom?: React.FC;
  VideoCallRoom: React.FC<{ hidden?: boolean }>;
  EndCallRoom?: React.FC;
  currentCallStep: CallSteps;
}

export default function CallFlow({
  WaitingRoom,
  ConnectingRoom,
  AccessWaitingRoom,
  AccessDeniedRoom,
  AccessGrantedRoom,
  VideoCallRoom,
  EndCallRoom,
  currentCallStep,
}: CallFlowProps) {
  // VideoCallRoom needs to always be active so that the subsribers can correctly be rendered
  // Instead we can hide/show the component with a style
  let ActiveCallComponent = ConnectingRoom;

  switch (currentCallStep) {
    case CallSteps.CONNECTING:
      ActiveCallComponent = ConnectingRoom;
      break;
    case CallSteps.WAITING:
      ActiveCallComponent = WaitingRoom;
      break;
    case CallSteps.REQUESTING_ACCESS:
      ActiveCallComponent = AccessWaitingRoom;
      break;
    case CallSteps.ACCESS_GRANTED:
      ActiveCallComponent = AccessGrantedRoom;
      break;
    case CallSteps.ACCESS_DENIED:
      ActiveCallComponent = AccessDeniedRoom;
      break;
    case CallSteps.END_CALL:
      ActiveCallComponent = EndCallRoom;
      break;
    case CallSteps.START_CALL:
      ActiveCallComponent = () => null;
      break;
    default:
      ActiveCallComponent = ConnectingRoom;
      break;
  }

  return (
    <>
      <ActiveCallComponent />
      <VideoCallRoom hidden={currentCallStep !== CallSteps.START_CALL} />
    </>
  );
}
