import { Connection, ConnectionEvent, UserType } from '../types';

// This is not imported from OT to keep it platform agnostic

export const checkConnectionByUserType = (
  connection?: Connection | ConnectionEvent
): UserType | null => {
  if (!connection) return null;

  const connectionData =
    (connection as Connection).data || (connection as ConnectionEvent).connection?.data;

  if (!connectionData) return null;

  try {
    return JSON.parse(connectionData).user_type || null;
  } catch (error) {
    return null;
  }
};

export const checkConnectionIsConsultant = (connection: Connection): boolean => {
  return checkConnectionByUserType(connection) === UserType.CONSULTANT;
};

export const checkConnectionIsGuest = (connection: Connection): boolean => {
  return checkConnectionByUserType(connection) === UserType.GUEST;
};

export const checkConnectionIsPatient = (connection: Connection): boolean => {
  return checkConnectionByUserType(connection) === UserType.PATIENT;
};
