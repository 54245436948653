export interface EventListener {
  event: string;
  callback: (data?: any) => void;
}
export default class EventListeners {
  private eventsArray: (EventListener | null)[] = [];

  public emit(event: string, data?: any): void {
    this.eventsArray.forEach(
      (eventListener) =>
        eventListener && eventListener.event === event && eventListener.callback(data)
    );
  }

  public on(event: string, callback: (data?: any) => void): () => void {
    const length = this.eventsArray.push({ event, callback });
    const index = length - 1;
    return () => this.remove(index);
  }

  public remove(index: number): void {
    if (this.eventsArray[index]) {
      this.eventsArray[index] = null;
    }
  }

  public getEventListeners(): (EventListener | null)[] {
    return this.eventsArray;
  }

  public removeAllEventListeners(): void {
    this.eventsArray = [];
  }
}
