import { createMmsCallFlowStep } from '../lib';
import { CallObserverSessionEvents } from '../lib/CallObserver/CallObserver';
import { CallObserverSignalAccessCallEvents } from '../lib/CallObserver/callObserverAccessCallHelpers';
import { latestEvents } from '../lib/CallObserver/helpers';
import { createAccessResponseSignal_deprecated } from '../signals/accessCall';
import {
  Flow,
  CallSteps,
  AccessCallSignalEvents,
  CommonSignalEvents,
  CallFlowStepNames,
  CallFlowStep,
  CallFlowUserType,
} from '../types';

const createClinicianCallFlowStep = (stepName: CallFlowStepNames) => {
  const flowObj: CallFlowStep = {
    call_flow_step_name: stepName,
    user_type: CallFlowUserType.clinician,
  };

  return createMmsCallFlowStep(flowObj);
};

export const clinicianRequestBubbleFlow: Flow = ({
  events,
  actions: { queueSignal, queueMmsCallFlowStep },
}) => {
  let step = CallSteps.NOT_STARTED;

  const getLatestEvent = latestEvents(events);
  const latestAccessEvent = getLatestEvent(
    CallObserverSignalAccessCallEvents.providerRequest,
    CallObserverSignalAccessCallEvents.clientGranted,
    CallObserverSignalAccessCallEvents.clientDeclined
  );

  switch (latestAccessEvent) {
    case CallObserverSignalAccessCallEvents.providerRequest:
      step = CallSteps.REQUESTING_ACCESS;
      queueSignal(
        createAccessResponseSignal_deprecated({ signalEvent: CommonSignalEvents.RECEIVED })
      );
      queueMmsCallFlowStep(
        createClinicianCallFlowStep(CallFlowStepNames.request_to_join_call_received),
        step
      );
      break;
    case CallObserverSignalAccessCallEvents.clientGranted:
      step = CallSteps.ACCESS_GRANTED;
      queueSignal(
        createAccessResponseSignal_deprecated({ signalEvent: AccessCallSignalEvents.GRANTED })
      );
      queueMmsCallFlowStep(
        createClinicianCallFlowStep(CallFlowStepNames.request_to_join_call_accepted),
        step
      );
      break;
    case CallObserverSignalAccessCallEvents.clientDeclined:
      step = CallSteps.ACCESS_DENIED;
      queueSignal(
        createAccessResponseSignal_deprecated({ signalEvent: AccessCallSignalEvents.DENIED })
      );
      queueMmsCallFlowStep(
        createClinicianCallFlowStep(CallFlowStepNames.request_to_join_call_rejected),
        step
      );
      break;
  }

  return step;
};
