import {
  AccessCallSignalEvents,
  IncomingSignalEvent,
  OutgoingSignal,
  SignalData,
  SignalEvents,
  SignalTypes,
} from '../types';

export interface CreateSignal extends SignalData {
  targetConnectionId?: string;
  signalType: SignalTypes;
}

export const createSignal = ({
  additionalData,
  signalEvent,
  targetConnectionId,
  signalType,
}: CreateSignal): OutgoingSignal => {
  const signal: OutgoingSignal = {
    type: signalType,
    data: '',
    retryOnReconnect: true,
  };

  if (targetConnectionId) {
    signal.to = targetConnectionId;
  }
  signal.data = JSON.stringify({ signalEvent, additionalData });
  return signal;
};

export const consumeSignal = (
  signalType: SignalTypes | string,
  event: IncomingSignalEvent,
  callback: (data?: SignalData) => void
) => {
  const sentSignalType = event.type.replace('signal:', '');
  if (sentSignalType !== signalType) return;
  let data: SignalData;
  try {
    data = JSON.parse(event.data);
  } catch (error) {
    //TODO: log to sentry
  }

  callback(data);
};

// Create old signal (deprecated)
export const createSignal_deprecated = ({
  additionalData,
  signalEvent,
  targetConnectionId,
  signalType,
}: CreateSignal): OutgoingSignal => {
  const signal: OutgoingSignal = {
    type: signalType,
    data: '',
    retryOnReconnect: true,
  };

  if (targetConnectionId) {
    signal.to = targetConnectionId;
  }

  if (additionalData?.fullName) {
    signal.data = JSON.stringify(additionalData);
  } else {
    signal.data = signalEvent || '';
  }

  return signal;
};

// Consume old signal (deprecated)
export const consumeSignal_deprecated = (
  signalType: SignalTypes | string,
  event: IncomingSignalEvent,
  callback: (data: SignalData) => void
) => {
  const sentSignalType = event.type.replace('signal:', '');
  if (sentSignalType !== signalType) return;
  let fullName;
  try {
    fullName = JSON.parse(event.data).fullName;
  } catch (error) {
    //TODO: log to sentry
  }

  callback({
    signalEvent: (fullName ? AccessCallSignalEvents.REQUEST : event.data) as SignalEvents,
    additionalData: fullName ? { fullName } : null,
  });
};
