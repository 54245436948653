export enum SignalTypes {
  generic = 'generic',
  access_call = 'access_call',
}

export enum CommonSignalEvents {
  RECEIVED = 'RECEIVED',
}

export enum AccessCallSignalEvents {
  GRANTED = 'GRANTED',
  DENIED = 'DENIED',
  REQUEST = 'REQUEST',
}

export type SignalEvents = CommonSignalEvents | AccessCallSignalEvents;

export interface SignalData {
  signalEvent?: SignalEvents;
  additionalData?: { [key: string]: any };
}

export interface CommonSignalHandlers {
  handleReceived: () => void;
}

export interface IncomingSignalEvent {
  data: string; // string of data sent (Can be any string including JSON)
  connectionId: string;
  sessionId: string;
  type: string; // type of signal sent
}

export interface OutgoingSignal {
  type: string; // type of signal to be sent
  data: string; // string of data sent (Can be any string including JSON)
  to?: string; // connectionID if sending to a specific participant
  retryOnReconnect: boolean;
}
