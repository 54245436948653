import {
  consumeSignal,
  consumeSignal_deprecated,
  createSignal,
  CreateSignal,
  createSignal_deprecated,
} from './helpers';

import {
  CommonSignalHandlers,
  CommonSignalEvents,
  IncomingSignalEvent,
  OutgoingSignal,
  SignalTypes,
  AccessCallSignalEvents,
} from '../types';

interface SendAccessRequestProps extends Pick<CreateSignal, 'targetConnectionId'> {
  fullName: string;
}

export const createAccessRequestSignal = ({
  fullName,
  targetConnectionId,
}: SendAccessRequestProps): OutgoingSignal => {
  return createSignal({
    additionalData: { fullName },
    targetConnectionId,
    signalType: SignalTypes.access_call,
    signalEvent: AccessCallSignalEvents.REQUEST,
  });
};

export const createAccessRequestSignal_deprecated = ({
  fullName,
  targetConnectionId,
}: SendAccessRequestProps): OutgoingSignal => {
  return createSignal_deprecated({
    additionalData: { fullName },
    targetConnectionId,
    signalType: SignalTypes.access_call,
    signalEvent: AccessCallSignalEvents.REQUEST,
  });
};

interface AccessRequestSignalHandlers {
  handleRequestData: (data: { [key: string]: any }) => void;
}
export const handleAccessRequestSignal = (
  event: IncomingSignalEvent,
  handlers: AccessRequestSignalHandlers
) => {
  return consumeSignal(SignalTypes.access_call, event, (data) => {
    if (data?.signalEvent !== AccessCallSignalEvents.REQUEST) return;
    if (!data.additionalData?.fullName) return;
    handlers.handleRequestData(data.additionalData);
  });
};
export const handleAccessRequestSignal_deprecated = (
  event: IncomingSignalEvent,
  handlers: AccessRequestSignalHandlers
) => {
  return consumeSignal_deprecated(SignalTypes.access_call, event, (data) => {
    if (data.signalEvent !== AccessCallSignalEvents.REQUEST) return;
    if (!data.additionalData?.fullName) return;
    handlers.handleRequestData(data.additionalData);
  });
};

export interface AccessResponseSignalHandlers extends CommonSignalHandlers {
  handleGranted?: () => void;
  handleDenied?: () => void;
}

interface SendAccessResponseProps extends Pick<CreateSignal, 'targetConnectionId'> {
  signalEvent: CommonSignalEvents | AccessCallSignalEvents;
  useBackwardsCompatibilityLayer?: boolean;
}

export const createAccessResponseSignal = ({
  signalEvent,
  targetConnectionId,
}: SendAccessResponseProps): OutgoingSignal => {
  return createSignal({
    signalEvent,
    targetConnectionId,
    signalType: SignalTypes.access_call,
  });
};
export const createAccessResponseSignal_deprecated = ({
  signalEvent,
  targetConnectionId,
}: SendAccessResponseProps): OutgoingSignal => {
  return createSignal_deprecated({
    signalEvent,
    targetConnectionId,
    signalType: SignalTypes.access_call,
  });
};
export const handleAccessResponseSignal = (
  event: IncomingSignalEvent,
  handlers: AccessResponseSignalHandlers
) => {
  return consumeSignal(SignalTypes.access_call, event, (data) => {
    switch (data?.signalEvent) {
      case CommonSignalEvents.RECEIVED:
        handlers.handleReceived();
        break;
      case AccessCallSignalEvents.DENIED:
        handlers.handleDenied();
        break;
      case AccessCallSignalEvents.GRANTED:
        handlers.handleGranted();
        break;
    }
  });
};

export const handleAccessResponseSignal_deprecated = (
  event: IncomingSignalEvent,
  handlers: AccessResponseSignalHandlers
) => {
  return consumeSignal_deprecated(SignalTypes.access_call, event, (data) => {
    switch (data.signalEvent) {
      case CommonSignalEvents.RECEIVED:
        handlers.handleReceived();
        break;
      case AccessCallSignalEvents.DENIED:
        handlers.handleDenied();
        break;
      case AccessCallSignalEvents.GRANTED:
        handlers.handleGranted();
        break;
    }
  });
};
