export enum CallFlowStepNames {
  notification_received = 'notification_received',
  notification_opened = 'notification_opened',
  connection_token_received = 'connection_token_received',
  join_call_button_tapped = 'join_call_button_tapped',
  call_waiting_room_joined = 'call_waiting_room_joined',
  request_to_join_call_submitted = 'request_to_join_call_submitted',
  call_joined = 'call_joined',
  call_left = 'call_left',
  request_to_join_call_received = 'request_to_join_call_received',
  request_to_join_call_accepted = 'request_to_join_call_accepted',
  request_to_join_call_rejected = 'request_to_join_call_rejected',
}

export enum CallType {
  VIDEO = 'VIDEO',
  VOICE = 'VOICE',
}

export enum CallFlowUserType {
  patient = 'patient',
  guest = 'guest',
  clinician = 'clinician',
}

export interface ExternalCallFlowStepPartial {
  app_name?: string;
  app_version?: string;
  platform_name?: string;
  platform_version?: string;
  call_id?: string;
  appointment_id: string; // appointment_id
  consultation_id?: string; // appointment_id (depricated - use appointment_id).
  consultation_uuid?: string;
  user_id?: string;
}

export interface InternalCallFlowStepPartial {
  call_flow_step_name: CallFlowStepNames | '';
  flow_step_type: CallFlowStepNames | '';
  call_type: CallType | '';
  user_type: CallFlowUserType | '';
  triggered_at: number;
  additional_data?: string;
}

export type CallFlowStep = Partial<InternalCallFlowStepPartial & ExternalCallFlowStepPartial>;
