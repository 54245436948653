export default class Queue {
  private queueArray = [];
  private history = [];
  public getQueue() {
    return this.queueArray;
  }
  public setQueue(newQueue: any[]) {
    this.queueArray = newQueue;
  }
  public push(item) {
    this.queueArray.push(item);
    this.history.push(item);
  }
  public pop() {
    return this.queueArray.shift();
  }
  public peek() {
    return this.getQueue()[0];
  }
  public reset() {
    this.setQueue([]);
    this.history = [];
  }
  public getHistory() {
    return this.history;
  }
}
