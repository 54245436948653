export enum CallSteps {
  NOT_STARTED = 'NOT_STARTED',
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
  WAITING = 'WAITING',
  REQUESTING_ACCESS = 'REQUESTING_ACCESS',
  ACCESS_DENIED = 'ACCESS_DENIED',
  ACCESS_GRANTED = 'ACCESS_GRANTED',
  END_CALL = 'END_CALL',
  START_CALL = 'START_CALL',
}
