import { createMmsCallFlowStep } from '../lib/CallFlowSteps/helpers';
import { CallObserverSessionEvents } from '../lib/CallObserver/CallObserver';
import { CallObserverSignalAccessCallEvents } from '../lib/CallObserver/callObserverAccessCallHelpers';
import { latestEvents } from '../lib/CallObserver/helpers';
import { createAccessRequestSignal_deprecated } from '../signals/accessCall';
import { Flow, CallSteps, UserType } from '../types';
import { CallFlowStep, CallFlowStepNames, CallFlowUserType } from '../types/CallFlowSteps';

const createGuestCallFlowStep = (stepName: CallFlowStepNames, additionalData?: string) => {
  const flowObj: CallFlowStep = {
    call_flow_step_name: stepName,
    user_type: CallFlowUserType.guest,
  };

  if (additionalData) {
    flowObj.additional_data = additionalData;
  }

  return createMmsCallFlowStep(flowObj);
};

const flow: Flow = ({
  activeParticipants,
  events,
  metadata,
  actions: { queueSignal, queueMmsCallFlowStep },
  outgoingSignal,
}) => {
  const getLatestEvent = latestEvents(events);
  const latestEvent = getLatestEvent(
    CallObserverSessionEvents.sessionConnected,
    CallObserverSignalAccessCallEvents.clientGranted,
    CallObserverSignalAccessCallEvents.clientDeclined,
    CallObserverSessionEvents.patientLeft,
    CallObserverSessionEvents.consultantLeft,
    CallObserverSessionEvents.forcedEndCall
  );
  let step = CallSteps.CONNECTING;

  switch (latestEvent) {
    case CallObserverSessionEvents.sessionConnected:
      step = CallSteps.WAITING;
      if (
        !(
          activeParticipants.includes(UserType.CONSULTANT) &&
          activeParticipants.includes(UserType.PATIENT)
        )
      ) {
        queueMmsCallFlowStep(
          createGuestCallFlowStep(CallFlowStepNames.call_waiting_room_joined),
          step
        );
        break;
      }

      queueSignal(createAccessRequestSignal_deprecated({ fullName: metadata.fullName }));

      if (outgoingSignal) {
        step = CallSteps.REQUESTING_ACCESS;
        queueMmsCallFlowStep(
          createGuestCallFlowStep(CallFlowStepNames.request_to_join_call_submitted),
          step
        );
      }
      break;
    case CallObserverSignalAccessCallEvents.clientGranted:
      step = CallSteps.START_CALL;
      queueMmsCallFlowStep(createGuestCallFlowStep(CallFlowStepNames.call_joined), step);
      break;
    case CallObserverSignalAccessCallEvents.clientDeclined:
      step = CallSteps.ACCESS_DENIED;
      queueMmsCallFlowStep(
        createGuestCallFlowStep(
          CallFlowStepNames.call_left,
          JSON.stringify({ reason: 'Denied access to the call' })
        ),
        step
      );
      break;
    case CallObserverSessionEvents.patientLeft:
      step = CallSteps.END_CALL;
      queueMmsCallFlowStep(
        createGuestCallFlowStep(
          CallFlowStepNames.call_left,
          JSON.stringify({ reason: 'Patient left the call' })
        ),
        step
      );
      break;
    case CallObserverSessionEvents.consultantLeft:
      step = CallSteps.END_CALL;
      queueMmsCallFlowStep(
        createGuestCallFlowStep(
          CallFlowStepNames.call_left,
          JSON.stringify({ reason: 'Consultant ended the call' })
        ),
        step
      );
      break;
    case CallObserverSessionEvents.forcedEndCall:
      step = CallSteps.END_CALL;
      queueMmsCallFlowStep(
        createGuestCallFlowStep(
          CallFlowStepNames.call_left,
          JSON.stringify({ reason: metadata.forceEndCallReason })
        ),
        step
      );
      break;
    default:
      break;
  }

  return step;
};

export const guestFlow = {
  flow,
  resetOnSteps: [CallSteps.END_CALL, CallSteps.ACCESS_DENIED],
};
