import { CallFlowStep } from '../../types/CallFlowSteps';

export const createMmsCallFlowStep = (
  callFlowStepData: Partial<CallFlowStep> = {}
): CallFlowStep => {
  const step = {
    triggered_at: new Date().getTime(),
    ...callFlowStepData,
  };

  if (callFlowStepData.appointment_id) {
    step.consultation_id = callFlowStepData.appointment_id;
  }

  if (callFlowStepData.call_flow_step_name) {
    step.flow_step_type = callFlowStepData.call_flow_step_name;
  }

  return step;
};

export const CALL_FLOW_STORE_KEY = 'mms_call_flow_steps_queue';

export interface SetCallFlowQueueProps {
  data: any; // any is here because there is additional internal data stored that is not captured in types
  setter: (key: string, data: any) => Promise<void>;
  namespace?: string;
  logger?: (message: string, error: Error) => void | Promise<void>;
}

export const setCallFlowQueue = async ({
  data,
  setter,
  namespace,
  logger,
}: SetCallFlowQueueProps) => {
  let key = CALL_FLOW_STORE_KEY;
  if (namespace) {
    key = `${namespace}__${key}`;
  }
  try {
    await setter(key, JSON.stringify(data));
  } catch (e) {
    if (logger) {
      logger(`[CCC | ${key}]: Could not set queue data`, e);
    }
  }
};

export interface GetCallFlowQueueProps {
  getter: (key: string) => Promise<string>;
  namespace?: string;
  logger?: (message: string, error: Error) => void | Promise<void>;
}

export const getCallFlowQueue = async ({
  getter,
  namespace,
  logger,
}: GetCallFlowQueueProps): Promise<any> => {
  let key = CALL_FLOW_STORE_KEY;
  if (namespace) {
    key = `${namespace}__${key}`;
  }
  try {
    const stringQueue = await getter(key);
    const json = JSON.parse(stringQueue);
    if (!json) return [];
    return json;
  } catch (e) {
    if (logger) {
      logger(`[CCC | ${key}]: Could not get queue data`, e);
    }
  }
  return [];
};
