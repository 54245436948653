import { EventListeners } from '../EventListeners';
import { Stream, Connection, IncomingSignalEvent, UserType } from '../../types';
import { checkConnectionByUserType } from '../../helpers';
import { canEmitEvent, getLatestEvent } from './helpers';
import {
  handleAccessRequestSignal,
  handleAccessRequestSignal_deprecated,
  handleAccessResponseSignal,
  handleAccessResponseSignal_deprecated,
} from '../../signals/accessCall';
import { CallObserverSignalAccessCallEvents } from './callObserverAccessCallHelpers';

export enum CallObserverSessionEvents {
  sessionConnected = 'sessionConnected',
  streamCreated = 'streamCreated',
  consultantJoined = 'consultantJoined',
  consultantLeft = 'consultantLeft',
  patientJoined = 'patientJoined',
  patientLeft = 'patientLeft',
  guestJoined = 'guestJoined',
  guestLeft = 'guestLeft',
  streamDestroyed = 'streamDestroyed',
  connectionCreated = 'connectionCreated',
  connectionDestroyed = 'connectionDestroyed',
  forcedEndCall = 'forcedEndCall',
}

export type CallObserverEvents = CallObserverSessionEvents | CallObserverSignalAccessCallEvents;
// CallEvents class should act to monitor the call events and not impact it.
export default class CallObserver extends EventListeners {
  private streams: Stream[] = [];
  private connections: Connection[] = [];
  private events: CallObserverEvents[] = [];
  private metadata: { [key: string]: any } = {};
  private logsEnabled = false;

  constructor() {
    super();
  }

  public getStreams(): Stream[] {
    return this.streams;
  }

  public getConnections(): Connection[] {
    return this.connections;
  }

  public getEvents(): CallObserverEvents[] {
    return this.events;
  }

  public getMetadata(): { [key: string]: any } {
    return this.metadata;
  }

  public addMetadata(metadata: { [key: string]: any }): { [key: string]: any } {
    this.metadata = { ...this.metadata, ...metadata };
    return this.metadata;
  }

  public on<T>(event: CallObserverEvents, callback: (event: T) => void): () => void {
    return super.on(event, callback);
  }

  private log(logName: string, data?: any): void {
    if (this.logsEnabled) {
      console.log('[CCC] ', logName, {
        data,
        activeParticipants: this.getActiveParticipants(),
        events: this.events,
        metadata: this.metadata,
      });
    }
  }

  public emit(event: CallObserverEvents, data?: any): void {
    this.log(event, data);
    this.events.push(event);
    super.emit(event, data);
  }

  public getStream(user: UserType): Stream | null {
    return (
      this.streams.find((stream) => checkConnectionByUserType(stream.connection) === user) || null
    );
  }

  public getConnection(user: UserType): Connection | null {
    return (
      this.connections.find((connection) => checkConnectionByUserType(connection) === user) || null
    );
  }

  public onConnectionCreated(event: Connection) {
    this.connections.push(event);
    this.emit(CallObserverSessionEvents.connectionCreated, event);
    this.emitParticipantJoined(event);
  }

  public onStreamCreated(event: Stream) {
    this.streams.push(event);
    this.emit(CallObserverSessionEvents.streamCreated, event);
    this.emitParticipantJoined(event.connection);
  }

  public onSessionConnected() {
    this.emit(CallObserverSessionEvents.sessionConnected);
  }

  public onStreamDestroyed(event: Stream) {
    this.streams = this.streams.filter((s) => s.streamId !== event.streamId);
    this.emit(CallObserverSessionEvents.streamDestroyed, event);
    this.emitParticipantLeft(event.connection);
  }

  public onConnectionDestroyed(event: Connection) {
    this.connections = this.connections.filter((s) => s.connectionId !== event.connectionId);
    this.emit(CallObserverSessionEvents.connectionDestroyed, event);
    this.emitParticipantLeft(event);
  }

  public emitForceEndCallEvent() {
    this.emit(CallObserverSessionEvents.forcedEndCall);
  }

  private emitParticipantJoined(connection: Connection) {
    switch (checkConnectionByUserType(connection)) {
      case UserType.CONSULTANT:
        if (
          canEmitEvent(
            getLatestEvent(
              this.events,
              CallObserverSessionEvents.consultantJoined,
              CallObserverSessionEvents.consultantLeft
            ),
            CallObserverSessionEvents.consultantLeft
          )
        ) {
          this.emit(CallObserverSessionEvents.consultantJoined);
        }
        break;
      case UserType.PATIENT:
        if (
          canEmitEvent(
            getLatestEvent(
              this.events,
              CallObserverSessionEvents.patientJoined,
              CallObserverSessionEvents.patientLeft
            ),
            CallObserverSessionEvents.patientLeft
          )
        ) {
          this.emit(CallObserverSessionEvents.patientJoined);
        }
        break;
      case UserType.GUEST:
        if (
          canEmitEvent(
            getLatestEvent(
              this.events,
              CallObserverSessionEvents.guestJoined,
              CallObserverSessionEvents.guestLeft
            ),
            CallObserverSessionEvents.guestLeft
          )
        ) {
          this.emit(CallObserverSessionEvents.guestJoined);
        }
        break;
    }
  }

  private emitParticipantLeft(connection: Connection) {
    switch (checkConnectionByUserType(connection)) {
      case UserType.CONSULTANT:
        if (
          canEmitEvent(
            getLatestEvent(
              this.events,
              CallObserverSessionEvents.consultantJoined,
              CallObserverSessionEvents.consultantLeft
            ),
            CallObserverSessionEvents.consultantJoined
          )
        ) {
          this.emit(CallObserverSessionEvents.consultantLeft);
        }
        break;
      case UserType.PATIENT:
        if (
          canEmitEvent(
            getLatestEvent(
              this.events,
              CallObserverSessionEvents.patientJoined,
              CallObserverSessionEvents.patientLeft
            ),
            CallObserverSessionEvents.patientJoined
          )
        ) {
          this.emit(CallObserverSessionEvents.patientLeft);
        }
        break;
      case UserType.GUEST:
        if (
          canEmitEvent(
            getLatestEvent(
              this.events,
              CallObserverSessionEvents.guestJoined,
              CallObserverSessionEvents.guestLeft
            ),
            CallObserverSessionEvents.guestJoined
          )
        ) {
          this.emit(CallObserverSessionEvents.guestLeft);
        }
        break;
    }
  }

  public getActiveParticipants(): UserType[] {
    return this.connections
      .map((connection) => checkConnectionByUserType(connection))
      .filter((participant) => !!participant);
  }

  public resetInstance() {
    this.streams = [];
    this.connections = [];
    this.events = [];
    this.metadata = {};
    this.logsEnabled = false;
    this.removeAllEventListeners();
  }

  public onSignal(event: IncomingSignalEvent) {
    this.log('incoming signal', event);
    handleAccessRequestSignal(event, {
      handleRequestData: (data) => {
        this.metadata.fullName = data.fullName;
        this.emit(CallObserverSignalAccessCallEvents.providerRequest);
      },
    });
    handleAccessResponseSignal(event, {
      handleReceived: () => {
        this.emit(CallObserverSignalAccessCallEvents.clientRequestAcknowledged);
      },
      handleGranted: () => {
        this.emit(CallObserverSignalAccessCallEvents.clientGranted);
      },
      handleDenied: () => {
        this.emit(CallObserverSignalAccessCallEvents.clientDeclined);
      },
    });
    handleAccessRequestSignal_deprecated(event, {
      handleRequestData: (data) => {
        this.metadata.fullName = data.fullName;
        this.emit(CallObserverSignalAccessCallEvents.providerRequest);
      },
    });
    handleAccessResponseSignal_deprecated(event, {
      handleReceived: () => {
        this.emit(CallObserverSignalAccessCallEvents.clientRequestAcknowledged);
      },
      handleGranted: () => {
        this.emit(CallObserverSignalAccessCallEvents.clientGranted);
      },
      handleDenied: () => {
        this.emit(CallObserverSignalAccessCallEvents.clientDeclined);
      },
    });
  }

  public enableLogging(): void {
    this.logsEnabled = true;
  }
}
